<template>
  <b-modal
    :visible="true"
    :title="$t('student-lab-module.lesson-links')"
    ok-only
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <div class="links-container">
      <b-button
        v-for="(link, index) in pageLinks"
        :key="index"
        variant="outline-primary"
        class="button-link"
        @click="openLink(link.link)"
      >
        {{ link.name }}
        <feather-icon
          icon="ExternalLinkIcon"
          size="16"
          class="ml-1"
        />
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { BButton, BModal } from 'bootstrap-vue'

export default {
  name: 'LessonLinks',
  components: {
    BButton,
    BModal,
  },
  props: {
    pageLinks: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    openLink(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

  <style scoped>
  .links-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .button-link {
    flex: 0 1 auto;
    /* This ensures buttons don't stretch unnecessarily */
  }
  </style>
