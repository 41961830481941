<template>
  <b-row>
    <b-col
      md="12"
      sm="12"
      class="mb-2 mb-md-0"
    >
      <b-overlay :show="isProcessing">
        <b-card
          v-if="!isProcessing && lessonGroups.length === 0"
        >
          <b-row>
            <b-col md="6"
                   class="border-right"
            >
              <h3 class="text-center font-italic"
                  style="top: 50%; position: relative;"
              >
                Lesson is not published yet, try to select other lessons.
              </h3>
            </b-col>
            <b-col md="6">
              <img src="/no-lessons.png"
                   class="img-fluid"
              >
            </b-col>
          </b-row>
        </b-card>
        <lesson-group-list
          v-else
          :lesson-groups="lessonGroups"
          :active-group="selectedGroup"
          :class-id="Number(classId)"
          :lesson-progress-list="lessonProgressList"
          :class-room-info="classRoomInfo"
          :all-loaders-history="allLoadersHistory"
          :selected-course-type="selectedCourseType"
          :selected-course-id="selectedCourseId"
          @showNotes="(id)=>viewingNotesOfLesson=id"
          @select-group="group => selectedGroup = group"
          @showLesson="(lesson, mode) => $emit('showLesson', lesson, mode)"
        >
          <template
            v-if="classRoomId"
            #back-button
          >
            <b-button
              variant="flat-secondary"
              class="btn-icon"
              @click="$emit('back')"
            >
              &#8701; {{ $t('actions.back') }}
            </b-button>
            | &nbsp;
          </template>
        </lesson-group-list>
      </b-overlay>

    </b-col>

    <lesson-notes-modal
      :show="viewingNotesOfLesson!=null"
      :lesson-id="viewingNotesOfLesson"
      :class-id="Number(classId)"
      @close="viewingNotesOfLesson=null"
    />
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BCard, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import useApollo from '@/@core/graphql/useApollo'
import GEC_EVENT_BUS from '@/utils/eventBus'
import LessonGroupList from './components/LessonGroupList.vue'
import LessonNotesModal from '../note/LessonNotesModal.vue'

export default ({
  components: {
    BRow, BCol, LessonGroupList, BButton, LessonNotesModal, BCard, BOverlay,
  },
  props: {
    selectedCourseId: {
      type: Number,
      default: null,
    },
    classRoomId: {
      type: Number,
      default: null,
    },
    selectedCourseType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lessonGroups: [],
      // classId: null,
      selectedGroup: null,
      self: getUserData(),
      viewingNotesOfLesson: null,
      classRoomInfo: null,
      lessonProgressList: [],
      allLoadersHistory: {},
      isProcessing: false,
      type: this.formatLoaderType(this.$route.query.type ? this.$route.query.type : null),
    }
  },
  computed: {
    courseId() {
      return this.selectedCourseId ?? parseInt(this.$route.params.id, 10)
    },
    classId() {
      return this.classRoomId ?? this.$route.params.classId
    },
  },
  watch: {
    '$route.query.group': {
      handler(name) {
        this.selectedGroup = name
      },
      deep: true,
      immediate: true,
    },
    // '$route.params.classId': {
    //   handler(id) {
    //     this.classId = id
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  created() {
    const { classId } = this
    const { courseId } = this
    const studentId = this.$route.query.student_id ? parseInt(this.$route.query.student_id, 10) : this.self.id
    this.getLessonsForCourse(classId, courseId, studentId)
    this.getClassRoomInfo()
    GEC_EVENT_BUS.$off('follow-up-updated')
    GEC_EVENT_BUS.$on('follow-up-updated', () => {
      this.lessonGroups = []
      this.getLessonsForCourse(classId, courseId, studentId)
    })
  },
  methods: {
    formatLoaderType(type) {
      switch (type) {
        case 'follow_up_practice':
          return 'follow_up'
        default:
          return type
      }
    },
    getClassRoomInfo() {
      useApollo.getClassRoomInfo(this.classId).then(response => {
        this.classRoomInfo = response.data.room
      })
    },
    getLessonsForCourse(classId, courseId, studentId) {
      this.isProcessing = true
      useJwt.getSelectedLessons({
        params: {
          cid: classId,
          courseId,
          uid: studentId,
        },
      }).then(res => {
        const coursesLists = res.data.data.data.sort((a, b) => a.group_order_index - b.group_order_index)
        this.course = coursesLists
        this.lessonProgressList = res.data.data.lesson_group_progress
        const { lessonLinks } = res.data.data
        const courses = coursesLists.reduce((groups, item) => {
          const group = (groups[item.group] || [])
          group.push(item)
          /* eslint-disable */
          groups[item.group] = group.map(item => ({
            ...item,
            pageLinks: lessonLinks?.filter(i => i.lessons?.includes(item.lesson_id)).map( i=> ({ name: i.name, link: i.link }))
          }))
          return groups
        }, {})

        if (Object.values(courses).length) {
          this.lessonGroups.push(courses)
          this.selectedGroup = this.selectedGroup || Object.keys(this.lessonGroups[0])[0]
        }
        this.allLoadersHistory = this.groupByKey(res.data.data.all_loaders, 'event_id')
        this.isProcessing = false
      })
    },
    viewClass() {
      this.$router.push({
        name: `${this.self.usertype}-view-students`,
        query: { group: this.selectedGroup },
        params: { id: this.classId, courseId: this.courseId },
      })
    },
  },
})
</script>
<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
  .lesson-group-title {
    background-color: #ffffff;
  }
  .dark-layout {
    .lesson-group-title {
      background-color: $theme-dark-card-bg !important;
      background-color: $theme-dark-box-shadow;
    }
  }
.semi-light-layout, 
.semi-light-reverse-layout, 
.green-layout, 
.brown-layout {
  .lesson-group-title {
    .btn-flat-secondary {
      color: #82868b !important;
      span {
        color: #82868b !important;
      }
    }
  }
}
</style>
